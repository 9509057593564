var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overlay-menu"},[_c('div',{ref:"wrap",staticClass:"overlay-menu__wrap"},[_c('nav',{staticClass:"overlay-menu__layout layout layout--menu"},[_c('div',{staticClass:"layout__container overlay-menu__navs"},[_c('div',{staticClass:"overlay-menu__nav"},[_c('ul',{staticClass:"overlay-menu__items overlay-menu__items--aside u-reset"},_vm._l((_vm.meta),function(ref,idx){
var icon = ref.icon;
var key = ref.key;
var label = ref.label;
var to = ref.to;
var url = ref.url;
return _c('li',{key:idx,staticClass:"overlay-menu__item"},[_c('ui-link',{staticClass:"overlay-menu__aside-link u-reset",attrs:{"to":to,"url":url},nativeOn:{"click":function($event){return _vm.onClick($event)}}},[(icon)?_c('ui-icon',{staticClass:"overlay-menu__nav-icon",attrs:{"name":icon}}):_vm._e(),_vm._v(" "),(key)?[_vm._v("\n                  "+_vm._s(_vm.$t(key))+"\n                ")]:[_vm._v("\n                  "+_vm._s(label)+"\n                ")]],2)],1)}),0)]),_vm._v(" "),_c('div',{staticClass:"overlay-menu__nav"},[_c('ul',{staticClass:"overlay-menu__items overlay-menu__items--main u-reset"},_vm._l((_vm.main),function(ref,idx){
var to = ref.to;
var key = ref.key;
var label = ref.label;
var url = ref.url;
return _c('li',{key:idx,staticClass:"overlay-menu__item"},[_c('ui-link',{staticClass:"overlay-menu__main-link u-reset",attrs:{"to":to,"url":url},nativeOn:{"click":function($event){return _vm.onClick($event)}}},[(key)?[_vm._v("\n                  "+_vm._s(_vm.$t(key))+"\n                ")]:[_vm._v("\n                  "+_vm._s(label)+"\n                ")]],2)],1)}),0)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }