var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-navigation layout layout--primary"},[_c('div',{staticClass:"layout__container"},[_c('div',{staticClass:"main-navigation__main"},[_c('div',{staticClass:"main-navigation__list"},[_c('ul',{staticClass:"u-reset main-navigation__items"},_vm._l((_vm.main),function(ref,idx){
var key = ref.key;
var label = ref.label;
var to = ref.to;
var url = ref.url;
return _c('li',{key:idx,staticClass:"main-navigation__item"},[_c('ui-link',{staticClass:"u-reset main-navigation__main-link",attrs:{"to":to,"url":url}},[(key)?[_vm._v("\n                "+_vm._s(_vm.$t(key))+"\n              ")]:[_vm._v("\n                "+_vm._s(label)+"\n              ")]],2)],1)}),0)]),_vm._v(" "),_c('n-link',{staticClass:"u-reset main-navigation__logo",attrs:{"to":_vm.homeLink}},[_c('ui-icon',{staticClass:"main-navigation__icon",attrs:{"name":"interaktionswerk-base"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"main-navigation__aside"},[_c('ul',{staticClass:"u-reset main-navigation__items"},_vm._l((_vm.meta),function(ref,idx){
var icon = ref.icon;
var key = ref.key;
var label = ref.label;
var to = ref.to;
var url = ref.url;
return _c('li',{key:idx,staticClass:"main-navigation__item"},[_c('ui-link',{staticClass:"u-reset main-navigation__aside-link",attrs:{"to":to,"url":url}},[(icon)?_c('ui-icon',{staticClass:"main-navigation__nav-icon",attrs:{"name":icon}}):_vm._e(),_vm._v(" "),(key)?[_vm._v("\n              "+_vm._s(_vm.$t(key))+"\n            ")]:[_vm._v("\n              "+_vm._s(label)+"\n            ")]],2)],1)}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }