import { mapFacets, createBemClass, DEFAULT_OPTIONS } from './util'

/**
 * Add BEM related helpers to a component
 * @param {string} bemRoot - The block name
 * @param {IBemMixinOptions} [config] - The mixin config
 * @return {object} The vue mixin
 */
export default function bemMixin(bemRoot, config) {
  const options = {
    ...DEFAULT_OPTIONS,
    useProp: true,
    ...config,
  }

  return {
    props: {
      facets: {
        type: Array,
        default() {
          return []
        },
      },
    },
    computed: {
      bemRoot() {
        return bemRoot
      },
      bemFacets() {
        return mapFacets(this.bemRoot, this.facets, options)
      },
    },
    methods: {
      bemAdd(modifierName, elementName, rootName) {
        if (!modifierName) {
          return ''
        }

        const blockName = rootName || this.bemRoot
        return createBemClass({ blockName, modifierName, elementName, ...options })
      },
    },
  }
}
