import { SET_MENU, SET_MENU_ITEMS } from './mutation-types'
import * as actions from './actions'

const mutations = {
  [SET_MENU](state, { isOpen }) {
    state.isOpen = isOpen
  },
  [SET_MENU_ITEMS](state, { main, meta }) {
    state.main = main
    state.meta = meta
  },
}

const state = () => ({
  isOpen: false,
  meta: [],
  main: [],
})

export default {
  namespaced: true,
  mutations,
  actions,
  state,
}
