import { mapState } from 'vuex'

export default {
  data() {
    return {
      scrollLockHelperStyleProp: 'paddingRight',
    }
  },
  computed: {
    ...mapState('scroll', ['isLocked', 'scrollbarWidth']),
    scrollLockStyles() {
      if (!process.client) {
        return ''
      }

      const hasRootScrollbar = document.body.offsetWidth - document.body.clientWidth > 0

      return this.isLocked && hasRootScrollbar
        ? { [this.scrollLockHelperStyleProp]: `${this.scrollbarWidth}px` }
        : {}
    },
  },
}
