<script>
  import { debounce } from 'lodash-es'
  import { mapActions } from 'vuex'
  import { hidden, visibilitychange } from '@/assets/js/util/dom/visibility-change'

  export default {
    props: {},
    mounted() {
      this.updateViewport()
      window.addEventListener('resize', this.updateViewport)
      window.addEventListener('orientationchange', this.updateViewport)
      document.addEventListener(visibilitychange, this.onVisibilityChange, false)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.updateViewport)
      window.removeEventListener('orientationchange', this.updateViewport)
      document.removeEventListener(visibilitychange, this.onVisibilityChange)
    },
    methods: {
      ...mapActions('viewport', ['setViewport', 'setPageVisibility']),
      updateViewport: debounce(
        function () {
          const { innerWidth: width, innerHeight: height } = window
          this.setViewport({ width, height })
        },
        100,
        {
          maxWait: 1000,
        },
      ),
      onVisibilityChange() {
        this.setPageVisibility({
          hidden: document[hidden],
        })
      },
    },
    render() {
      return null
    },
  }
</script>
