<template>
  <component :is="rootTag" v-bind="rootAttrs">
    <slot name="default" />
  </component>
</template>

<script>
  export default {
    props: {
      to: {
        type: Object,
        default: null,
      },
      url: {
        type: String,
        default: null,
      },
    },
    computed: {
      rootTag() {
        return this.to ? 'n-link' : 'a'
      },
      rootAttrs() {
        return {
          ...(this.to ? { to: this.getLink(this.to) } : { href: this.url }),
          ...this.$attrs,
        }
      },
    },
    methods: {
      getLink(to) {
        return this.localePath(to)
      },
    },
  }
</script>
