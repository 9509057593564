<template>
  <div class="overlay-root">
    <ui-overlay v-for="(value, key) in overlays" :id="key" :key="key" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import UiOverlay from '@/components/ui-overlay/ui-overlay'

  export default {
    components: {
      UiOverlay,
    },
    computed: {
      ...mapState('overlay', ['overlays']),
    },
  }
</script>
