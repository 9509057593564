import { SET_PAGE_VISIBILITY, SET_VIEWPORT } from '@/store/viewport/mutation-types'
import * as actions from './actions'

const mutations = {
  [SET_VIEWPORT](state, { width, height }) {
    state.width = width
    state.height = height
  },
  [SET_PAGE_VISIBILITY](state, { hidden }) {
    state.hidden = hidden
  },
}

const state = () => ({
  height: 0,
  width: 0,
  hidden: false,
  mq: null,
})

export default {
  namespaced: true,
  mutations,
  actions,
  state,
}
