<script>
  import { throttle } from 'lodash-es'
  import { mapActions, mapState } from 'vuex'
  import bemMixin from '@/assets/js/mixin/bem'

  const ROOT_CLASS = 'scroll-root'

  export default {
    mixins: [bemMixin(ROOT_CLASS)],
    props: {},
    data() {
      return {
        scrollLockPosition: null,
        scrollLockURL: null,
      }
    },
    computed: {
      ...mapState('scroll', ['position', 'scrollbarWidth', 'isLocked']),
    },
    watch: {
      isLocked: {
        handler(val) {
          const { scrollHeight, offsetHeight } = document.scrollingElement

          if (offsetHeight - scrollHeight === 0 && val) {
            return
          }

          const lockedClass = this.bemAdd('is-locked', null, ROOT_CLASS)
          const currentURL = new URL(window.location.pathname, window.location.href).href

          document.documentElement.classList[val ? 'add' : 'remove'](lockedClass)
          document.body.style.top = val ? `${this.position * -1}px` : ''
          document.body.style.paddingRight = val ? `${this.scrollbarWidth}px` : ''

          if (val) {
            this.scrollLockPosition = this.position
            this.scrollLockURL = currentURL
          } else {
            // Don't reset scroll lock position when url was changed
            if (this.scrollLockURL !== currentURL) {
              return
            }

            // NOTE: Force a vuex update for all registered components
            if (document.body.scrollTop === this.scrollLockPosition) {
              window.scrollTo(0, this.scrollLockPosition + 1)
              this.setScroll()
            }

            window.scrollTo(0, this.scrollLockPosition)
            this.setScroll()
          }
        },
      },
    },
    mounted() {
      this.setScroll()
      document.documentElement.classList.add(ROOT_CLASS)
      window.addEventListener('scroll', this.throttledSetScroll)
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.throttledSetScroll)
    },
    methods: {
      ...mapActions('scroll', ['setScroll']),
      throttledSetScroll: throttle(function () {
        this.setScroll()
      }, 100),
    },
    render() {
      return null
    },
  }
</script>
