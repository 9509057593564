<template>
  <button
    :class="rootClasses"
    class="navigation-btn"
    :aria-label="$t('accessibility.navigation_button_label')"
    v-on="$listeners"
  >
    <span class="navigation-btn__root">
      <span v-for="n in 3" :key="n" class="navigation-btn__bar" />
    </span>
  </button>
</template>

<script>
  import bemMixin from '@/assets/js/mixin/bem'
  import { mapState } from 'vuex'

  export default {
    mixins: [bemMixin('navigation-btn')],
    props: {
      isActive: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapState('navigation', ['isOpen', 'meta']),
      rootClasses() {
        return [
          this.bemAdd(this.isActive ? 'is-active' : ''),
          this.bemAdd(this.isOpen ? 'has-open-menu' : 'has-closed-menu'),
        ]
      },
    },
  }
</script>

<style lang="scss" src="./_navigation-btn.scss" />
