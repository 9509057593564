import { ROUTE_IDS } from '@/assets/js/util/nuxt/route'

export const MENU_LIST = [
  {
    routeId: ROUTE_IDS.SERVICES,
  },
  {
    routeId: ROUTE_IDS.BLOG,
  },
]
