import Vue from 'vue'
import OverlayMenu from '@/components/overlay-menu/overlay-menu'

const components = {
  OverlayMenu,
}

Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component)
})

export default () => {}
