<template>
  <div :class="rootClasses" class="main-menu">
    <div :style="scrollLockStyles" class="main-menu__bar">
      <main-navigation class="main-menu__navigation" />
      <n-link :to="homeLink" class="u-reset main-menu__brand" @click.native="onHomeClick">
        <ui-icon class="main-menu__icon" name="interaktionswerk-base" />
      </n-link>
      <div class="main-menu__burger">
        <navigation-btn :is-active="isOpen" class="main-menu__btn" @click="onClick" />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import bemMixin from '@/assets/js/mixin/bem'
  import scrollLockHelperMixin from '@/assets/js/mixin/scroll-lock-helper'
  import NavigationBtn from '@/components/navigation-btn/navigation-btn'
  import UiIcon from '@/components/ui-icon/ui-icon'
  import MainNavigation from '@/components/main-navigation/main-navigation'
  import { ROUTE_IDS, ROUTE_LIST } from 'assets/js/util/nuxt/route'

  export default {
    components: { MainNavigation, UiIcon, NavigationBtn },
    mixins: [bemMixin('main-menu'), scrollLockHelperMixin],
    computed: {
      ...mapState('navigation', ['isOpen']),
      rootClasses() {
        return [this.bemAdd(this.isOpen ? 'is-open' : '')]
      },
      homeLink() {
        const { name } = ROUTE_LIST.find(({ id }) => id === ROUTE_IDS.HOME)
        return this.localePath({ name })
      },
    },
    methods: {
      ...mapActions('navigation', ['setMenu']),
      onClick() {
        this.setMenu({ isOpen: !this.isOpen })
      },
      onHomeClick() {
        this.setMenu({ isOpen: false })
      },
    },
  }
</script>

<style lang="scss" src="./_main-menu.scss" />
