<template>
  <div class="overlay-menu">
    <div ref="wrap" class="overlay-menu__wrap">
      <nav class="overlay-menu__layout layout layout--menu">
        <div class="layout__container overlay-menu__navs">
          <div class="overlay-menu__nav">
            <ul class="overlay-menu__items overlay-menu__items--aside u-reset">
              <li
                v-for="({ icon, key, label, to, url }, idx) in meta"
                :key="idx"
                class="overlay-menu__item"
              >
                <ui-link
                  :to="to"
                  :url="url"
                  class="overlay-menu__aside-link u-reset"
                  @click.native="onClick"
                >
                  <ui-icon v-if="icon" :name="icon" class="overlay-menu__nav-icon" />
                  <template v-if="key">
                    {{ $t(key) }}
                  </template>
                  <template v-else>
                    {{ label }}
                  </template>
                </ui-link>
              </li>
            </ul>
          </div>
          <div class="overlay-menu__nav">
            <ul class="overlay-menu__items overlay-menu__items--main u-reset">
              <li
                v-for="({ to, key, label, url }, idx) in main"
                :key="idx"
                class="overlay-menu__item"
              >
                <ui-link
                  :to="to"
                  :url="url"
                  class="overlay-menu__main-link u-reset"
                  @click.native="onClick"
                >
                  <template v-if="key">
                    {{ $t(key) }}
                  </template>
                  <template v-else>
                    {{ label }}
                  </template>
                </ui-link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import bemMixin from '@/assets/js/mixin/bem'
  import UiIcon from '@/components/ui-icon/ui-icon'
  import UiLink from '@/components/ui-link/ui-link'

  const MD_BREAKPOINT_WIDTH = 768

  export default {
    components: { UiLink, UiIcon },
    mixins: [bemMixin('overlay-menu')],
    props: {},
    computed: {
      ...mapState('viewport', ['width']),
      ...mapState('navigation', ['main', 'meta']),
    },
    watch: {
      width(value) {
        if (value >= MD_BREAKPOINT_WIDTH) {
          this.setMenu({ isOpen: false })
        }
      },
    },
    mounted() {
      // document.body.addEventListener('click', this.onClickAway)
    },
    beforeDestroy() {
      // document.body.removeEventListener('click', this.onClickAway)
    },
    methods: {
      ...mapActions('navigation', ['setMenu']),
      onClick() {
        this.setMenu({ isOpen: false })
      },
      // onClickAway({ target }) {
      //   const { wrap } = this.$refs
      //   const isInside = wrap.contains(target) || target === wrap
      //
      //   if (isInside) {
      //     return
      //   }
      //
      //   this.onClick()
      // },
    },
  }
</script>

<style lang="scss" src="./_overlay-menu.scss" />
