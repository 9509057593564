import { MENU_LIST } from '@/assets/js/util/nuxt/menu'
import { SET_MENU_ITEMS } from '@/store/navigation/mutation-types'
import { ROUTE_IDS, ROUTE_LIST } from '@/assets/js/util/nuxt/route'

const PAGE_ROUTE = ROUTE_LIST.find(({ id }) => id === ROUTE_IDS.PAGE)

export const actions = {
  async nuxtServerInit({ commit }, { $content }) {
    const meta = await $content('navigation', 'meta').fetch()
    const mainRaw = await $content(PAGE_ROUTE.content)
      .where({ show_in_menu: true })
      .sortBy('order', 'asc')
      .fetch()

    const main = mainRaw.map(({ slug, title }) => ({
      label: title,
      to: { name: PAGE_ROUTE.name, params: { [PAGE_ROUTE.param]: slug } },
    }))

    const distributors = MENU_LIST.map(({ routeId }) => {
      const { name, translationId } = ROUTE_LIST.find(({ id }) => id === routeId)
      const key = `meta.${translationId}.title`

      return { key, to: { name } }
    })

    await commit(
      `navigation/${SET_MENU_ITEMS}`,
      {
        main: [...distributors, ...main],
        meta: meta.navigation_items,
      },
      { root: true },
    )
  },
}
