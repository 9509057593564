// NOTE: Source: `https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API`
let _hidden
let _visibilitychange

if (process.client) {
  if (typeof window.document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    _hidden = 'hidden'
    _visibilitychange = 'visibilitychange'
  } else if (typeof window.document.msHidden !== 'undefined') {
    _hidden = 'msHidden'
    _visibilitychange = 'msvisibilitychange'
  } else if (typeof window.document.webkitHidden !== 'undefined') {
    _hidden = 'webkitHidden'
    _visibilitychange = 'webkitvisibilitychange'
  }
}

export const hidden = _hidden
export const visibilitychange = _visibilitychange
