import { overlayInstanceIds } from '@/assets/js/model/overlay-instance'
import { SET_MENU } from './mutation-types'

/**
 * Update menu state
 */
export function setMenu({ commit, dispatch }, { isOpen }) {
  if (isOpen) {
    dispatch(
      'overlay/openOverlay',
      {
        id: overlayInstanceIds.MENU,
        transition: 'trs-menu-fade',
        facets: ['menu'],
        component: 'overlay-menu',
        disableScroll: true,
      },
      { root: true },
    )
  } else {
    dispatch(
      'overlay/closeOverlay',
      {
        id: overlayInstanceIds.MENU,
        transition: 'trs-menu-fade',
        disableScroll: false,
      },
      { root: true },
    )
  }

  commit(SET_MENU, { isOpen })
}
