<template>
  <div class="p-layout p-layout--default u-typography-root">
    <nuxt />
    <main-menu />
    <overlay-root class="u-typography-root" />
    <client-only>
      <scroll-observer />
      <viewport-observer />
    </client-only>
  </div>
</template>

<script>
  import OverlayRoot from '@/components/overlay-root/overlay-root'
  import ScrollObserver from '@/components/scroll-observer/scroll-observer'
  import ViewportObserver from '@/components/viewport-observer/viewport-observer'
  import MainMenu from '@/components/main-menu/main-menu'
  import linkClickRoutingMixin from '@/assets/js/mixin/link-click-routing'

  export default {
    components: { MainMenu, ViewportObserver, ScrollObserver, OverlayRoot },
    mixins: [linkClickRoutingMixin],
  }
</script>
