export const ROUTE_IDS = {
  HOME: 'HOME',
  PAGE: 'PAGE',
  BLOG: 'BLOG',
  BLOG_ENTRY: 'BLOG_ENTRY',
  SERVICES: 'SERVICES',
  SERVICES_ENTRY: 'SERVICES_ENTRY',
}

export const ROUTE_LIST = [
  {
    id: ROUTE_IDS.HOME,
    translationId: 'home',
    name: 'index',
  },
  {
    id: ROUTE_IDS.PAGE,
    name: 'id',
    content: 'pages',
    param: 'id',
  },
  {
    id: ROUTE_IDS.BLOG,
    translationId: 'blog',
    name: 'blog',
    content: 'blog',
  },
  {
    id: ROUTE_IDS.BLOG_ENTRY,
    translationId: 'blog_entry',
    name: 'blog-id',
    content: 'blog',
    param: 'id',
  },
  {
    id: ROUTE_IDS.SERVICES,
    translationId: 'services',
    name: 'dienstleistungen',
    content: 'services',
  },
  {
    id: ROUTE_IDS.SERVICES_ENTRY,
    translationId: 'services_entry',
    name: 'dienstleistungen-id',
    content: 'services',
    param: 'id',
  },
]
