<template>
  <div class="main-navigation layout layout--primary">
    <div class="layout__container">
      <div class="main-navigation__main">
        <div class="main-navigation__list">
          <ul class="u-reset main-navigation__items">
            <li
              v-for="({ key, label, to, url }, idx) in main"
              :key="idx"
              class="main-navigation__item"
            >
              <ui-link :to="to" :url="url" class="u-reset main-navigation__main-link">
                <template v-if="key">
                  {{ $t(key) }}
                </template>
                <template v-else>
                  {{ label }}
                </template>
              </ui-link>
            </li>
          </ul>
        </div>
        <n-link :to="homeLink" class="u-reset main-navigation__logo">
          <ui-icon class="main-navigation__icon" name="interaktionswerk-base" />
        </n-link>
      </div>
      <div class="main-navigation__aside">
        <ul class="u-reset main-navigation__items">
          <li
            v-for="({ icon, key, label, to, url }, idx) in meta"
            :key="idx"
            class="main-navigation__item"
          >
            <ui-link :to="to" :url="url" class="u-reset main-navigation__aside-link">
              <ui-icon v-if="icon" :name="icon" class="main-navigation__nav-icon" />
              <template v-if="key">
                {{ $t(key) }}
              </template>
              <template v-else>
                {{ label }}
              </template>
            </ui-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import { mapState } from 'vuex'
  import UiIcon from '@/components/ui-icon/ui-icon.vue'
  import UiLink from '@/components/ui-link/ui-link.vue'
  import { ROUTE_IDS, ROUTE_LIST } from '@/assets/js/util/nuxt/route'

  export default Vue.extend({
    components: { UiLink, UiIcon },
    computed: {
      ...mapState('navigation', ['main', 'meta']),
      homeLink() {
        const { name } = ROUTE_LIST.find(({ id }) => id === ROUTE_IDS.HOME)
        return this.localePath({ name })
      },
    },
  })
</script>

<style lang="scss" src="./_main-navigation.scss" />
