<template>
  <component :is="name" :class="rootClasses" class="ui-icon">
    <slot name="default" />
  </component>
</template>

<script>
  import Vue from 'vue'
  import { kebabCase } from 'lodash-es'
  import bemMixin from '@/assets/js/mixin/bem'
  import InteraktionswerkBase from '@/assets/svg/iaw_base.svg?inline'
  import InteraktionswerkWrapped from '@/assets/svg/iaw_wrapped.svg?inline'
  import Twitter from '@/assets/svg/twitter.svg?inline'

  const components = {
    InteraktionswerkBase,
    InteraktionswerkWrapped,
    Twitter,
  }

  export default Vue.extend({
    components,
    mixins: [bemMixin('ui-icon')],
    props: {
      name: {
        type: String,
        required: true,
        validator(value) {
          return Object.keys(components).some((component) => {
            return component === value || kebabCase(component) === value
          })
        },
      },
      size: {
        type: String,
        default: null,
      },
    },
    computed: {
      rootClasses() {
        return [...this.bemFacets, this.bemAdd(this.size)]
      },
    },
  })
</script>

<style lang="scss" src="./_ui-icon.scss" />
